import { useEffect, useState, useContext } from 'react';
import { useLocation, Routes, Route, Link } from 'react-router-dom';
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import Case from './pages/Case';
import ChangePw from './pages/ChangePw';
import Header from './components/Header';
//import LanguageSwitch from './components/languageSwitch';


import { UserProvider } from './User';

interface LocalizationData {
  [key: string]: string;
}

interface OriginalObject {
  [key: number]: {
      error_status: number; doc_id: string; status: number; word_count: any; doc_name: string; download_link: any; error_msg: string; priority: number; case_id: string; job_id: string; created: string; last_update: string; start_processing: string; finished_processing: string; collected: string;
  };
}

function App() {

  const location = useLocation();

  useEffect(() => {
    require('preline/preline');
  }, []);

  useEffect(() => {
    // @ts-ignore
    HSStaticMethods.autoInit();
  }, [location.pathname]);




  return (
    <UserProvider>
    <>
      
      <Header />

      <div className="bg-white/60 min-h-[calc(100vh-80px)] dark:text-white dark:bg-black/60">
        <Routes >
          <Route path="/" element={<Home />} />
          <Route path="/Dashboard" element={<Dashboard  />} />
          <Route path="/Case" element={<Case />} />
          <Route path="/changepw" element={<ChangePw />} />
        </Routes>
      </div>
    </>
    
    </UserProvider>
  );
}

export default App;
